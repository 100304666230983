<template>
    <modal ref="modalCanjearRecompensa" titulo="Canjear recompensa" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0 px-4 pt-4">
            <div class="col-5">
                <div class="row mx-0">
                    <div class="btn-claim mb-4">
                        dadawda
                    </div>
                </div>
                <p class="text-general f-15 f-500 mb-2">
                    Convertir gemas y monedas a dinero
                </p>
                <div class="row mx-0 align-items-end mb-3">
                    <img src="/img/ilustraciones/gaming/i_moneda.svg" height="45" />
                    <div class="col-5">
                        <p class="text-general f-12 pl-3">
                            Convertir
                        </p>
                        <el-input v-model="moneda" placeholder="Monedas" class="w-100" />
                    </div>
                    <div class="col-auto text-purple f-22 px-0 f-600 font-omnes">
                        $ 6.000
                    </div>
                </div>
                <div class="row mx-0 align-items-end">
                    <img src="/img/ilustraciones/gaming/i_gema.svg" height="45" />
                    <div class="col-5">
                        <p class="text-general f-12 pl-3">
                            Convertir
                        </p>
                        <el-input v-model="gemas" placeholder="Gemas" class="w-100" />
                    </div>
                    <div class="col-auto text-purple f-22 px-0 f-600 font-omnes">
                        $ 6.000
                    </div>
                </div>
                <div class="bg-general mt-4 text-center cr-pointer text-white d-flex justify-content-start a-center px-1 mx-0 br-8 f-15" style="height:44px;">
                    <div class="br-8 px-2 f-600 d-middle-center" style="background-color:#0000004d;width:34px;height:34px;">
                        {{ cant_carrito }}
                    </div>
                    <div class="ml-auto tres-puntos mr-4">
                        Canjear
                    </div>
                </div>
            </div>
            <div class="col-6">
                <p class="text-black f-600 f-24">
                    Cupones de recompensa <br /> disponibles
                </p>
                <div v-for="(data, idx) in 6" :key="idx" class="row mx-0 align-items-center mb-3">
                    <div class="col">
                        <div class="card-cupon d-flex position-relative cr-pointer" :class="cuponActivo == idx + 1 ? 'border-active' : ''" @click="cuponActivo = idx + 1">
                            <div class="space-img pr-3 h-100 d-middle">
                                <img src="/img/ilustraciones/descount.svg" width="50" height="50" />
                            </div>
                            <div class="col px-2">
                                <div class="row mx-0 align-items-end text-purple pt-2">
                                    <div class="col-auto px-0 f-600 f-30 text-cupon">
                                        $ 10%
                                    </div>
                                    <small class="pl-2">
                                        Descuento
                                    </small>
                                </div>
                                <p class="text-detail mt-3">
                                    Primera compra
                                </p>
                                <p class="text-fetch mt-1">
                                    Expira: 10 Abr. 2022
                                </p>
                            </div>
                            <!-- Circulo cupo -->
                            <div :class="`circled-coupon${ cuponActivo == idx + 1 ? '-active' : '' }`" />
                        </div>
                    </div>
                    <div class="col-auto text-purple f-24 f-600">
                        No usar
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            cant_carrito: 15,
            cuponActivo: null,
            moneda: '',
            gemas: ''
        }
    },
    methods:{
        toggle(){
            this.$refs.modalCanjearRecompensa.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-claim{
    width: 312px;
    height: 74px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
}

.card-cupon{
    width: 100%;
    height: 108px;
    border-radius: 12px;
    padding-left: 35px;
    box-shadow: 0px 2px 4px #00000014;
    z-index: 2;
    .circled-coupon{
        width: 44px;
        position: absolute;
        left: -22px;
        top: 31px;
        height: 44px;
        background-color: white;
        z-index: 1;
        border-radius: 50%;
        box-shadow: 1px 0px 4px #00000014;
        &::after{
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            z-index: 2;
            height: 44px;
            width: 44px;
            background-color: #FFFFFF
        }
        &-active{
            @extend .circled-coupon;
            border: 1px solid var(--color-general);
        }
    }
    &.border-active{
        border: 1px solid var(--color-general);
    }
    .space-img{
        border-right: dashed 3px #F6F6F6;
    }
    .text-cupon{
        line-height: 29px;
    }
    .text-detail{
        color: #b1b9c1;
    }
    .text-fetch{
        color: #D3D7DB;
    }
}
</style>